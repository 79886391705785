import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, IconButton, InputLabel, Menu, MenuItem, OutlinedInput, Tooltip, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { tokens } from '../../theme';
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfiniteScroll from 'react-infinite-scroll-component';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PaidIcon from '@mui/icons-material/Paid';
import PostDialog from './PostDialog';

function UsersPage() {
    const { admin, token } = useContext(AdminContext);
    const colors = tokens();
    const userId = window.location.pathname.split("/")[3];
    const [user, setUser] = useState(null);
    const [posts, setPosts] = useState([]);
    const [hasMorePost, setHasMorePost] = useState(false);
    const [coins, setCoins] = useState([]);
    const [hasMoreCoin, setHasMoreCoin] = useState(false);
    const [targetPost, setTargetPost] = useState(null);
    const [editPostDialogOpen, setEditPostDialogOpen] = useState(false);

    const updatePost = async (postId, post) => {
        if (post.content) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadPostContent?token=${token}`, {
                postContent: post.content
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete post.content;
                        delete post.content_link;
                        post.content_path = response.data.contentPath;

                        if (post.content_path.split(".").pop() === "jpg" ||
                            post.content_path.split(".").pop() === "png" ||
                            post.content_path.split(".").pop() === "jpeg" ||
                            post.content_path.split(".").pop() === "webp") {
                            post.type = "photo";
                        } else {
                            post.type = "video";
                        }
                    }
                });
        }

        var postToUpdate = { ...post };
        delete postToUpdate.content;
        delete postToUpdate.content_link;
        delete postToUpdate.user_profile_photo_link;
        delete postToUpdate.username;
        delete postToUpdate.subscription_price;
        delete postToUpdate.like_count;
        delete postToUpdate.comment_count;

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updatePost?token=${token}&postId=${postId}`, {
            post: postToUpdate
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchPosts(0);
                    if (editPostDialogOpen) {
                        setEditPostDialogOpen(false);
                    }
                }
            });
    }

    const removePost = async (postId) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deletePost?token=${token}&postId=${postId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchPosts(0);
                }
            });
    }

    const fetchPosts = async (startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUserPosts?token=${token}&userId=${userId}&startIndex=${startIndex}&limit=20`)
            .catch((err) => {
                setPosts([]);
                setHasMorePost(false)
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200 && response.data.posts) {
                    setHasMorePost(response.data.posts && response.data.posts.length === 20);
                    if (startIndex === 0) {
                        setPosts([...response.data.posts]);
                    } else {
                        setPosts((prev) => [...prev, ...response.data.posts]);
                    }
                }
            });
    }

    const fetchCoins = async (startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUserCoinHistory?token=${token}&userId=${userId}&startIndex=${startIndex}&limit=20`)
            .catch((err) => {
                setCoins([]);
                setHasMoreCoin(false)
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200 && response.data.coins) {
                    setHasMoreCoin(response.data.coins && response.data.coins.length === 20);
                    if (startIndex === 0) {
                        setCoins([...response.data.coins]);
                    } else {
                        setCoins((prev) => [...prev, ...response.data.coins]);
                    }
                }
            });

    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUser?token=${token}&userId=${userId}`)
            .catch((err) => {
                setUser(null);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUser(response.data.user);
                    fetchPosts(0);
                    fetchCoins(0);
                }
            });
    }, [userId]);

    useEffect(() => {
        if (targetPost) {
            setEditPostDialogOpen(true);
        }
    }, [targetPost]);

    const handleEditPostDialogClose = () => {
        setTargetPost(null);
        setEditPostDialogOpen(false);
    }

    return (
        <Box sx={{ p: "75px" }}>
            <PostDialog dialogOpen={editPostDialogOpen} handleClose={handleEditPostDialogClose} targetPost={targetPost} handleFormSubmit={(values) => updatePost(targetPost.id, values)} />
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title={user?.username ? user.username : "Kullanıcı Adı"} subtitle="Bu sayfadan kullanıcının coin geçmişini görebilir, gönderilerini görebilir ve yönetebilirsiniz." />

                </Box>
            </Box>

            <Box m="30px 20px 0 20px" pb="30px">
                <Accordion disabled={posts.length < 1}>
                    <AccordionSummary id='post-accordion' expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h4'>Gönderiler</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InfiniteScroll
                            dataLength={posts.length}
                            next={() => fetchPosts(posts.length)}
                            hasMore={hasMorePost}
                            loader={<h4>Yükleniyor...</h4>}
                            height={700}
                        >
                            <Box m="10px 0 0 0" maxHeight="500px">
                                {posts.map((post) =>
                                    <Box key={post.id + "p"} display="inline-block" p="20px" mr="20px" sx={{
                                        background: colors.primary[400], borderRadius: "20px", width: "310px"
                                    }}>
                                        <Box>
                                            {post.type === "photo" &&
                                                <Box display="flex" alignItems="center" justifyContent="center" mb="10px">
                                                    <img
                                                        alt="profile-user"
                                                        src={post.content_link}
                                                        style={{ cursor: "pointer", borderRadius: "5%", height: "150px", maxWidth: "270px" }} />
                                                </Box>
                                            }
                                            {post.type === "video" &&
                                                <Box display="flex" alignItems="center" justifyContent="center" mb="10px">
                                                    <video
                                                        alt="profile-user"
                                                        src={post.content_link}
                                                        controls={true}
                                                        style={{ cursor: "pointer", borderRadius: "5%", height: "150px", maxWidth: "270px" }} />
                                                </Box>
                                            }
                                            <Tooltip title={post.text} >
                                                <p style={{ textAlign: 'center', display: "block", textOverflow: "ellipsis", wordWrap: "break-word", overflow: "hidden", height: "2.4em", lineHeight: "1.2em", }}>{post.text}</p>
                                            </Tooltip>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-evenly" my="10px">
                                            <Box display="flex" gap="5px" alignItems="center" mt="10px">
                                                <FavoriteIcon />
                                                <Typography variant='h5'>{post.like_count}</Typography>
                                            </Box>
                                            <Box display="flex" gap="5px" alignItems="center" mt="10px">
                                                <ChatBubbleIcon />
                                                <Typography variant='h5'>{post.comment_count}</Typography>
                                            </Box>
                                            <Box display="flex" gap="5px" alignItems="center" mt="10px" sx={post.price > 0 ? {} : { color: "grey" }}>
                                                <PaidIcon />
                                                <Typography variant='h5'>{post.price}</Typography>
                                            </Box>
                                        </Box>
                                        <hr />
                                        <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly" mt="15px">
                                            <Button variant='contained' color="warning" onClick={() => { setTargetPost(post) }} sx={{ textTransform: "none", fontSize: "13px" }}>Düzenle</Button>
                                            <Button variant='contained' color="error" onClick={() => { removePost(post.id) }} sx={{ textTransform: "none", fontSize: "13px" }}>Sil</Button>
                                        </Box>
                                    </Box>)}
                            </Box>
                        </InfiniteScroll>
                    </AccordionDetails>
                </Accordion>
                <Accordion disabled={coins.length < 1}>
                    <AccordionSummary id='coin-accordion' expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h4'>Coin Geçmişi</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InfiniteScroll
                            dataLength={coins.length}
                            next={() => fetchCoins(coins.length)}
                            hasMore={hasMoreCoin}
                            loader={<h4>Yükleniyor...</h4>}
                            height={510}
                        >
                            <Box m="10px 0 0 0" maxHeight="500px">
                                {coins.map((coin) => <Box key={coin.id + "c"} display="inline-block" p="20px" mr="20px" mt="20px" sx={{
                                    background: colors.primary[400], borderRadius: "20px", width: "310px"
                                }}>
                                    <Typography variant='h4'>{coin.count > 0 ? coin.count : -1 * coin.count} adet coin {coin.count > 0 ? "eklendi." : "harcandı."}</Typography>
                                    <hr />
                                    <Box display="flex" gap="10px" alignItems="center" justifyContent="center" mt="10px">
                                        <Box padding="5px 10px" sx={{ background: coin.withdrawable ? colors.greenAccent[600] : colors.redAccent[600], borderRadius: "10px" }}>
                                            <Typography variant='h6'>{coin.withdrawable ? "Çekilebilir" : "Çekilemez"}</Typography>
                                        </Box>
                                        <Box padding="5px 10px" sx={{ background: colors.blueAccent[800], borderRadius: "10px" }}>
                                            <Typography variant='h6'>İşlem Tarihi: {new Date(coin.created_date).toLocaleDateString()}</Typography>
                                        </Box>
                                    </Box>
                                </Box>)}
                            </Box>
                        </InfiniteScroll>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    )
}

export default UsersPage
import { Box, Button, Tooltip, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { tokens } from '../../theme';
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShortTextIcon from '@mui/icons-material/ShortText';

function WithdrawsPage() {
    const { admin, token } = useContext(AdminContext);
    const colors = tokens();
    const [withdraws, setWithdraws] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const fetchWithdraws = (startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getWithdraws?token=${token}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                setWithdraws([]);
                setHasMore(false);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.withdraws && response.data.withdraws.length === 50);
                    if (startIndex === 0) {
                        setWithdraws([...response.data.withdraws]);
                    } else {
                        setWithdraws((prev) => [...prev, ...response.data.withdraws]);
                    }
                }
            });
    }

    const approveWithdraw = async (withdrawId) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateWithdraw?token=${token}&withdrawId=${withdrawId}`, {
            withdraw: {
                status: 1
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchWithdraws(0);
                }
            });
    }

    const rejectWithdraw = async (withdraw) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/rejectWithdraw?token=${token}`, {
            withdraw: {
                id: withdraw.id,
                user_id: withdraw.user_id,
                coin_count: withdraw.coin_count
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchWithdraws(0);
                }
            });
    }

    useEffect(() => {
        fetchWithdraws(0);
    }, []);


    return (
        <Box sx={{ p: "75px" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Para Çekim Talepleri" subtitle="Bu sayfadan para çekim taleplerini görebilir ve yönetebilirsiniz." />
                </Box>
            </Box>

            <Box>
                <InfiniteScroll
                    dataLength={withdraws.length}
                    next={() => fetchWithdraws(withdraws.length)}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>{withdraws && withdraws.length > 0 ? "Tüm talepleri görüntülüyorsunuz." : "Yeni talep yok."}</b>
                        </p>
                    }
                >
                    {withdraws.map((withdraw, i) =>
                    (<Box key={i + "w"} display="inline-block" p="20px" mr="20px" mt="20px" sx={{
                        background: colors.primary[400], borderRadius: "20px", width: "310px"
                    }}>
                        <Box textAlign="center">
                            <img
                                alt="profile-user"
                                src={withdraw.profile_photo_link}
                                width="120px"
                                height="100px"
                                style={{ cursor: "pointer", borderRadius: "50%" }} />
                            <Tooltip title={(withdraw.name + " " + withdraw.surname).length >= 18 ? withdraw.name + " " + withdraw.surname : ""}>
                                <Typography variant='h3' mt="15px">{((withdraw.name + " " + withdraw.surname).length >= 18 ? (withdraw.name + " " + withdraw.surname).slice(0, 15) + "..." : withdraw.name + " " + withdraw.surname)}</Typography>
                            </Tooltip>
                        </Box>
                        <hr />
                        <Box display="flex" gap="10px" alignItems="center" mt="20px">
                            <PersonIcon />
                            <Typography variant='h5'>{withdraw.username}</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" mt="10px">
                            <AccountBalanceIcon />
                            <Typography variant='h5'>{withdraw.bank_name}</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" mt="10px">
                            <ShortTextIcon />
                            <Typography variant='h5'>{withdraw.iban}</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" justifyContent="center" mt="10px">
                            <Box padding="5px 10px" sx={{ background: colors.greenAccent[800], borderRadius: "10px" }}>
                                <Typography variant='h6'>{withdraw.coin_count} coin</Typography>
                            </Box>
                            <Box padding="5px 10px" sx={{ background: colors.blueAccent[600], borderRadius: "10px" }}>
                                <Typography variant='h6' color="white">{withdraw.tl_value} ₺</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" justifyContent="center" mt="10px">
                            <Box padding="5px 10px" sx={{ background: colors.redAccent[800], borderRadius: "10px" }}>
                                <Typography variant='h6'>Talep Tarihi: {new Date(withdraw.created_date).toLocaleDateString()}</Typography>
                            </Box>
                        </Box>
                        {withdraw.status === 0 &&
                            <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly" mt="20px">
                                <Button variant='contained' color="success" onClick={() => approveWithdraw(withdraw.id)} sx={{ textTransform: "none", fontSize: "13px" }}>Ödeme Yapıldı</Button>
                                <Button variant='contained' color="error" onClick={() => rejectWithdraw(withdraw)} sx={{ textTransform: "none", fontSize: "13px" }}>Talebi Reddet</Button>
                            </Box>}
                        {withdraw.status !== 0 &&
                            <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly" mt="20px">
                                <Box padding="5px 10px" sx={{ background: withdraw.status === 1 ? colors.greenAccent[500] : colors.redAccent[500], borderRadius: "10px" }}>
                                    <Typography variant='h6' color={withdraw.status === 1 ? "black" : "white"}>{withdraw.status === 1 ? "Ödeme Yapıldı" : "Talep Reddedildi"}</Typography>
                                </Box>
                            </Box>}
                    </Box>)
                    )}
                </InfiniteScroll>
            </Box>

        </Box>
    )
}

export default WithdrawsPage
import { Box, Button, Tooltip, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { tokens } from '../../theme';
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import PersonIcon from '@mui/icons-material/Person';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';

function EarningRequestsPage() {
    const { admin, token } = useContext(AdminContext);
    const colors = tokens();
    const [isLoading, setLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const fetchEarningRequests = (startIndex) => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getRequestsToBeSubscribable?token=${token}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                setRequests([]);
                setHasMore(false);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.requests && response.data.requests.length === 50);
                    if (startIndex === 0) {
                        setRequests([...response.data.requests]);
                    } else {
                        setRequests((prev) => [...prev, ...response.data.requests]);
                    }
                }
                setLoading(false);
            });
    }

    const approveEarningRequest = async (userId) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateUser?token=${token}&userId=${userId}`, {
            user: {
                subscription_requested: 0,
                is_subscribable: 1,
                subscription_price: 100
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchEarningRequests(0);
                }
            });
    }

    const declineEarningRequest = async (userId) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateUser?token=${token}&userId=${userId}`, {
            user: {
                subscription_requested: 0,
                is_subscribable: 0
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchEarningRequests(0);
                }
            });
    }

    useEffect(() => {
        fetchEarningRequests(0);
    }, []);


    return (
        <Box sx={{ p: "75px" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Para Kazanma Talepleri" subtitle="Bu sayfadan para kazanma taleplerini görebilir ve yönetebilirsiniz." />
                </Box>
            </Box>

            <Box>
                <InfiniteScroll
                    dataLength={requests.length}
                    next={() => fetchEarningRequests(requests.length)}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>{requests && requests.length > 0 ? "Tüm talepleri görüntülüyorsunuz." : "Yeni talep yok."}</b>
                        </p>
                    }
                >
                    {requests.map((user, i) =>
                    (<Box key={i} display="inline-block" p="20px" mr="20px" mt="20px" sx={{
                        background: colors.primary[400], borderRadius: "20px", width: "310px"
                    }}>
                        <Box textAlign="center">
                            <img
                                alt="profile-user"
                                src={user.profile_photo_link}
                                width="120px"
                                height="100px"
                                style={{ cursor: "pointer", borderRadius: "50%" }} />
                            <Tooltip title={(user.name + " " + user.surname).length >= 18 ? user.name + " " + user.surname : ""}>
                                <Typography variant='h3' mt="15px">{((user.name + " " + user.surname).length >= 18 ? (user.name + " " + user.surname).slice(0, 15) + "..." : user.name + " " + user.surname)}</Typography>
                            </Tooltip>
                        </Box>
                        <hr />
                        <Box display="flex" gap="10px" alignItems="center" mt="20px">
                            <PersonIcon />
                            <Typography variant='h5'>{user.username}</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" mt="10px">
                            <AttachEmailOutlinedIcon />
                            <Typography variant='h5'>{user.email}</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" justifyContent="center" mt="10px">
                            <Box padding="5px 10px" sx={{ background: colors.greenAccent[800], borderRadius: "10px" }}>
                                <Typography variant='h6'>{user.follower_count} takipçi</Typography>
                            </Box>
                            <Box padding="5px 10px" sx={{ background: colors.blueAccent[600], borderRadius: "10px" }}>
                                <Typography variant='h6' color="white">{user.followed_count} takip edilen</Typography>
                            </Box>
                            <Box padding="5px 10px" sx={{ background: user.is_verified ? colors.greenAccent[600] : colors.redAccent[600], borderRadius: "10px" }}>
                                <Typography variant='h6'>{user.is_verified ? "Onaylı" : "Onaysız"}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" justifyContent="center" mt="10px">
                            <Box padding="5px 10px" sx={{ background: colors.redAccent[800], borderRadius: "10px" }}>
                                <Typography variant='h6'>Kayıt Tarihi: {new Date(user.created_date).toLocaleDateString()}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly" mt="20px">
                            <Button variant='contained' color="success" onClick={() => approveEarningRequest(user.id)} sx={{ textTransform: "none", fontSize: "13px" }}>Talebi Onayla</Button>
                            <Button variant='contained' color="error" onClick={() => declineEarningRequest(user.id)} sx={{ textTransform: "none", fontSize: "13px" }}>Talebi Reddet</Button>
                        </Box>
                    </Box>)
                    )}
                </InfiniteScroll>
            </Box>

        </Box>
    )
}

export default EarningRequestsPage
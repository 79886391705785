import { Box, Button, Tooltip, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { tokens } from '../../theme';
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import PersonIcon from '@mui/icons-material/Person';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';

function ReportedPosts() {
    const { admin, token } = useContext(AdminContext);
    const colors = tokens();
    const [isLoading, setLoading] = useState(true);
    const [reports, setReports] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const fetchPostReports = (startIndex) => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getPostReports?token=${token}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                setReports([]);
                setHasMore(false);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.reports && response.data.reports.length === 50);
                    if (startIndex === 0) {
                        setReports([...response.data.reports]);
                    } else {
                        setReports((prev) => [...prev, ...response.data.reports]);
                    }
                }
                setLoading(false);
            });
    }

    const removePost = async (postId) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deletePost?token=${token}&postId=${postId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchPostReports(0);
                }
            });
    }

    const removePostReport = async (postId) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deletePostReport?token=${token}&postId=${postId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchPostReports(0);
                }
            });
    }

    useEffect(() => {
        fetchPostReports(0);
    }, []);


    return (
        <Box sx={{ p: "75px" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Gönderi Şikayetleri" subtitle="Bu sayfadan gönderi şikayetlerini görebilir ve yönetebilirsiniz." />
                </Box>
            </Box>

            <Box>
                <InfiniteScroll
                    dataLength={reports.length}
                    next={() => fetchPostReports(reports.length)}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>{reports && reports.length > 0 ? "Tüm şikayetleri görüntülüyorsunuz." : "Yeni talep yok."}</b>
                        </p>
                    }
                >
                    {reports.map((report, i) =>
                    (<Box key={i} display="inline-block" p="20px" mr="20px" mt="20px" sx={{
                        background: colors.primary[400], borderRadius: "20px", width: "310px"
                    }}>
                        <Box>
                            {report.type === "photo" &&
                                <Box display="flex" alignItems="center" justifyContent="center" mb="10px">
                                    <img
                                        alt="profile-user"
                                        src={report.content_link}
                                        style={{ cursor: "pointer", borderRadius: "5%", height: "150px", maxWidth: "270px" }} />
                                </Box>
                            }
                            {report.type === "video" &&
                                <Box display="flex" alignItems="center" justifyContent="center" mb="10px">
                                    <video
                                        alt="profile-user"
                                        src={report.content_link}
                                        controls={true}
                                        style={{ cursor: "pointer", borderRadius: "5%", height: "150px", maxWidth: "270px" }} />
                                </Box>
                            }
                            <Tooltip title={report.text} >
                                <p style={{ textAlign: 'center', display: "block", "text-overflow": "ellipsis", "word-wrap": "break-word", overflow: "hidden", "height": "2.4em", "line-height": "1.2em", }}>{report.text}</p>
                            </Tooltip>
                        </Box>
                        <hr />
                        <Box display="flex" gap="10px" alignItems="center" mt="10px">
                            <PersonIcon />
                            <Typography variant='h5'>{report.username}</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" mt="10px">
                            <AttachEmailOutlinedIcon />
                            <Typography variant='h5'>{report.email}</Typography>
                        </Box>
                        <Box display="flex" gap="10px" ml="5px" alignItems="center">
                            <p style={{ textAlign: 'center' }}><b>{report.report_count}</b> defa şikayet edildi.</p>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
                            <Button variant='contained' color="success" onClick={() => removePost(report.id)} sx={{ textTransform: "none", fontSize: "13px" }}>Gönderiyi Sil</Button>
                            <Button variant='contained' color="error" onClick={() => removePostReport(report.id)} sx={{ textTransform: "none", fontSize: "13px" }}>Şikayeti Sil</Button>
                        </Box>
                    </Box>)
                    )}
                </InfiniteScroll>
            </Box>

        </Box>
    )
}

export default ReportedPosts
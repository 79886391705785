import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import { tokens } from '../../theme'
import { Formik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import { useContext } from 'react';
import { AdminContext } from '../../context/AdminContext';

function LoginPage() {
    const colors = tokens();
    const { setToken } = useContext(AdminContext);

    var initialValues = {
        username: "",
        password: ""
    }

    var validationObject = {
        username: yup.string().required("required"),
        password: yup.string().required("required")
    }

    const adminSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/login`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setToken(response.data.token);
            } else {
                //TODO: show there is no user dialog
            }
        });
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Box bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={adminSchema}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="25px" mb="25px">
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"username"}
                                    value={values.username}
                                    error={!!touched.username && !!errors.username}
                                    helperText={touched.username && errors.username}
                                    sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="password"
                                    label="Password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"password"}
                                    value={values.password}
                                    error={!!touched.password && !!errors.password}
                                    helperText={touched.password && errors.password}
                                    sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                <Button type="submit" color="success" variant="contained">
                                    <Typography variant='h5'>Login</Typography>
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}

export default LoginPage
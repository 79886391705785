import { Route, Routes } from "react-router-dom";
import Sidebar from "./global/Sidebar";
import { useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useContext } from "react";
import { AdminContext } from "./context/AdminContext";
import LoginPage from "./pages/LoginPage";
import UsersPage from "./pages/UsersPage";
import UserDetailsPage from "./pages/UserDetailsPage";
import Topbar from "./global/Topbar";
import AdminsPage from "./pages/AdminsPage";
import EarningRequestsPage from "./pages/EarningRequestsPage";
import ReportedPosts from "./pages/ReportedPosts";
import WithdrawsPage from "./pages/WithdrawsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

function App() {
  const { setToken, admin, token, isLoading } = useContext(AdminContext);
  const theme = useMode();
  if (admin && token) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/admin-panel/*" element={<Topbar />} />
        </Routes>
        <div className="app">
          <Routes>
            <Route path="/admin-panel/*" element={<Sidebar />} />
          </Routes>
          <main className="content pl80">
            <Routes>
              {admin.users_perm === 1 && <Route exact path="/admin-panel/" element={<UsersPage />} />}
              {admin.users_perm === 1 && <Route exact path="/admin-panel/users" element={<UsersPage />} />}
              {admin.users_perm === 1 && <Route exact path="/admin-panel/users/*" element={<UserDetailsPage />} />}
              {admin.withdraws_perm === 1 && <Route exact path="/admin-panel/earning-requests" element={<EarningRequestsPage />} />}
              {admin.withdraws_perm === 1 && <Route exact path="/admin-panel/withdraws" element={<WithdrawsPage />} />}
              {admin.admins_perm === 1 && <Route exact path="/admin-panel/admins" element={<AdminsPage />} />}
              {admin.reports_perm === 1 && <Route exact path="/admin-panel/reports" element={<ReportedPosts />} />}
              <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    );
  } else if (!isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content" >
            <Routes>
              <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/admin-panel/*" element={<LoginPage />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    )
  }
}

export default App;

import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function UserDialog({ targetUser, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        username: targetUser?.username ? targetUser?.username : "",
        name: targetUser?.name ? targetUser?.name : "",
        surname: targetUser?.surname ? targetUser?.surname : "",
        biography: targetUser?.biography ? targetUser?.biography : "",
        withdrawable_coin: targetUser?.withdrawable_coin !== undefined && targetUser?.withdrawable_coin !== null ? targetUser?.withdrawable_coin : "",
        nonwithdrawable_coin: targetUser?.nonwithdrawable_coin !== undefined && targetUser?.nonwithdrawable_coin !== null ? targetUser?.nonwithdrawable_coin : "",
        photo: null,
        profile_photo_link: targetUser?.profile_photo_link ? targetUser?.profile_photo_link : ""
    };

    var validationObject = {
        username: yup.string().required("Field is required"),
        name: yup.string().required("Field is required"),
        surname: yup.string().required("Field is required"),
        biography: yup.string(),
        withdrawable_coin: yup.number().min(0, "Cant be less than 0").required("Field is required"),
        nonwithdrawable_coin: yup.number().min(0, "Cant be less than 0").required("Field is required"),
        photo: yup.mixed().nullable().when('profile_photo_link', {
            is: (profile_photo_link) => !profile_photo_link || profile_photo_link === "",
            then: yup.string().nonNullable("").required('Field is required')
        }),
        profile_photo_link: yup.string()
    }

    const userSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    Kullanıcıyı Düzenle
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">Profil Fotoğrafı</Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.photo || (values.profile_photo_link && values.profile_photo_link !== "")) && <img alt='square brand logo' width="100px" src={values.photo ? URL.createObjectURL(values.photo) : values.profile_photo_link} />}
                                        <input id="photo" name="photo" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("photo", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(6, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kullanıcı Adı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"username"}
                                        value={values.username}
                                        error={!!touched.username && !!errors.username}
                                        helperText={touched.username && errors.username}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="İsim"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Soyisim"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"surname"}
                                        value={values.surname}
                                        error={!!touched.surname && !!errors.surname}
                                        helperText={touched.surname && errors.surname}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Biyografi"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={4}
                                        name={"biography"}
                                        value={values.biography}
                                        error={!!touched.biography && !!errors.biography}
                                        helperText={touched.biography && errors.biography}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Çekilebilir Coin"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"withdrawable_coin"}
                                        value={values.withdrawable_coin}
                                        error={!!touched.withdrawable_coin && !!errors.withdrawable_coin}
                                        helperText={touched.withdrawable_coin && errors.withdrawable_coin}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Çekilemez Coin"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"nonwithdrawable_coin"}
                                        value={values.nonwithdrawable_coin}
                                        error={!!touched.nonwithdrawable_coin && !!errors.nonwithdrawable_coin}
                                        helperText={touched.nonwithdrawable_coin && errors.nonwithdrawable_coin}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default UserDialog
import { Box, FormControl, IconButton, InputLabel, Menu, MenuItem, OutlinedInput } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import VerifiedIcon from '@mui/icons-material/Verified';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../theme';
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import UserDialog from './UserDialog';
import { useNavigate } from 'react-router-dom';

function UsersPage() {
    const { admin, token } = useContext(AdminContext);
    const colors = tokens();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [searchId, setSearchId] = useState(null);
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [targetUser, setTargetUser] = useState(null);
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const updateUser = async (userId, user) => {
        var userIx = users.findIndex(u => u.id === userId);
        var newWithdrawableCoin = user.withdrawable_coin;
        var newNonwithdrawableCoin = user.nonwithdrawable_coin;
        if (userIx !== -1 && newWithdrawableCoin && newWithdrawableCoin !== users[userIx].withdrawable_coin) {
            var wCoinToAdd = newWithdrawableCoin - users[userIx].withdrawable_coin;
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/addCoin?token=${token}&userId=${userId}&count=${wCoinToAdd}&withdrawable=${1}`)
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {

                    }
                });
        }
        if (userIx !== -1 && newNonwithdrawableCoin && newNonwithdrawableCoin !== users[userIx].nonwithdrawable_coin) {
            var nwCoinToAdd = newNonwithdrawableCoin - users[userIx].nonwithdrawable_coin;
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/addCoin?token=${token}&userId=${userId}&count=${nwCoinToAdd}&withdrawable=${0}`)
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {

                    }
                });
        }
        if (user.photo) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadUserProfilePhoto?token=${token}`, {
                profilePhoto: user.photo
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete user.photo;
                        delete user.profile_photo_link;
                        user.profile_photo_path = response.data.contentPath;
                    }
                });
        }



        var userToUpdate = { ...user };
        delete userToUpdate.photo;
        delete userToUpdate.profile_photo_link;
        delete userToUpdate.withdrawable_coin;
        delete userToUpdate.nonwithdrawable_coin;
        delete userToUpdate.followed_count;
        delete userToUpdate.follower_count;
        delete userToUpdate.subscriber_count;

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateUser?token=${token}&userId=${userId}`, {
            user: userToUpdate
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (userIx !== null && userIx !== undefined && userIx !== -1) {
                        setUsers(prev => {
                            var newUsers = [...prev];
                            newUsers[userIx] = { ...newUsers[userIx], ...userToUpdate };
                            if (newWithdrawableCoin) {
                                newUsers[userIx].withdrawable_coin = newWithdrawableCoin;
                            }
                            if (newNonwithdrawableCoin) {
                                newUsers[userIx].nonwithdrawable_coin = newNonwithdrawableCoin;
                            }
                            return newUsers;
                        })
                    }
                    if (editUserDialogOpen) {
                        setEditUserDialogOpen(false);
                    }
                }
            });
    }

    const deleteUser = async (id) => {
        var userIx = users.findIndex(u => u.id === id);
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteUserWithoutApple?token=${token}&id=${id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (userIx !== null && userIx !== undefined && userIx !== -1) {
                        setUsers(prev => {
                            var newUsers = [...prev];
                            newUsers.splice(userIx, 1)
                            return newUsers;
                        })
                    }
                    if (editUserDialogOpen) {
                        setEditUserDialogOpen(false);
                    }
                }
            });
    }

    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'profile_photo_link', headerName: "Profil Fotoğrafı", sortable: false, renderCell: (params) => <img src={params.value} alt='profile-pic' style={{ height: "30px", width: "30px" }} />, width: 70 },
        {
            field: 'username',
            headerName: 'Kullanıcı Adı'
        }, {
            field: 'name',
            headerName: 'İsim'
        }, {
            field: 'surname',
            headerName: 'Soyisim'
        }, {
            field: 'email',
            headerName: 'E-Posta'
        }, {
            field: 'biography',
            headerName: 'Biyografi'
        }, {
            field: 'followed_count',
            headerName: 'Takip Edilen Sayısı'
        }, {
            field: 'follower_count',
            headerName: 'Takipçi Sayısı'
        }, {
            field: 'subscriber_count',
            headerName: 'Abone Sayısı'
        }, {
            field: 'withdrawable_coin',
            headerName: 'Çekilebilir Coin'
        }, {
            field: 'nonwithdrawable_coin',
            headerName: 'Çekilemez Coin'
        }, {
            field: 'is_subscribable',
            headerName: 'Abone Olunabilir',
            type: "boolean"
        }, {
            field: 'is_verified',
            headerName: 'Onaylı Kullanıcı',
            type: "boolean"
        }, {
            field: 'subscription_price',
            headerName: 'Abonelik Fiyatı'
        }, {
            field: "created_date", headerName: "Kayıt Tarihi",
            renderCell: ({ row: { created_date } }) => {
                return new Date(created_date).toLocaleDateString()
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<InfoIcon />}
                    label="Kullanıcı Detayı"
                    onClick={() => navigate(`/admin-panel/users/${params.row.id}`)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<PersonIcon />}
                    label="Kullanıcıyı Düzenle"
                    onClick={() => setTargetUser(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<VerifiedIcon />}
                    label={params.row.is_verified === 1 ? "Kullanıcı Onayını Kaldır" : "Kullanıcıyı Onayla"}
                    onClick={() => updateUser(params.row.id, { is_verified: (params.row.is_verified === 1 ? 0 : 1) })}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<VerifiedIcon />}
                    label={params.row.is_subscribable === 1 ? "Kullanıcıyı Abone Olunamaz Yap" : "Kullanıcıyı Abone Olunabilir Yap"}
                    onClick={() => updateUser(params.row.id, { subscription_requested: 0, is_subscribable: (params.row.is_subscribable === 1 ? 0 : 1), subscription_price: (params.row.is_subscribable === 1 ? 0 : 100) })}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label={"Kullanıcıyı Sil"}
                    color='red'
                    onClick={() => deleteUser(params.row.id, params.row.firebase_id)}
                    showInMenu
                />
            ],
        }
    ];

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUsers?token=${token}&startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${searchId ? `&searchId=${searchId}` : ""}${orderModel ? `&orderField=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setUsers([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUsers(response.data.users);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel]);

    useEffect(() => {
        if (targetUser) {
            setEditUserDialogOpen(true);
        }
    }, [targetUser]);

    const handleEditUserDialogClose = () => {
        setTargetUser(null);
        setEditUserDialogOpen(false);
    }

    return (
        <Box sx={{ p: "75px" }}>
            <UserDialog dialogOpen={editUserDialogOpen} handleClose={handleEditUserDialogClose} targetUser={targetUser} handleFormSubmit={(values) => updateUser(targetUser.id, values)} />
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Kullanıcılar" subtitle="Bu sayfadan kullanıcıları görebilir ve yönetebilirsiniz." />
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Ara</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Ara"
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box-id">Ara(Id)</InputLabel>
                        <OutlinedInput
                            id="search-box-id"
                            type='number'
                            onChange={(e) => {
                                setSearchId(e.target.value ? e.target.value : "");
                            }}
                            label="Ara(Id)"
                        />
                    </FormControl>

                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <DataGrid
                rows={users}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                onRowDoubleClick={(row) => {
                    navigate(`/admin-panel/users/${row.id}`);
                }}
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    }
                }}
            />
        </Box>
    )
}

export default UsersPage
import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';

function SettingsDialog({ dialogOpen, handleClose }) {
    const { admin, token } = useContext(AdminContext);
    const [settings, setSettings] = useState(null)

    var initialValues = {
        payment_on_private_chat: settings?.payment_on_private_chat ? settings?.payment_on_private_chat : 0,
        earning_on_private_chat: settings?.earning_on_private_chat ? settings?.earning_on_private_chat : 0,
        one_coin_tl_price_double: settings?.one_coin_tl_price_double ? settings?.one_coin_tl_price_double : 0,
        minimum_withdrawable_coin: settings?.minimum_withdrawable_coin ? settings?.minimum_withdrawable_coin : 0,
    };

    var validationObject = {
        payment_on_private_chat: yup.number().min(0, "0'dan küçük olamaz").required("Zorunlu"),
        earning_on_private_chat: yup.number().min(0, "0'dan küçük olamaz").required("Zorunlu"),
        one_coin_tl_price_double: yup.number().min(0, "0'dan küçük olamaz").required("Zorunlu"),
        minimum_withdrawable_coin: yup.number().min(0, "0'dan küçük olamaz").required("Zorunlu")
    }

    const settingSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateSettings?token=${token}`, {
            settings: {
                ...values
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setSettings(values);
                handleClose();
            }
        });
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getSettings?token=${token}`)
            .catch((err) => {
                setSettings(null);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setSettings(response.data.settings);
                }
            });
    }, []);


    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Ayarlar
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mb="25px">
                                    <Typography variant='h6' sx={{ gridColumn: "span 4", marginTop: "10px" }}>Özel Mesajlaşma</Typography>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Ödenen Coin Miktarı(dk)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"payment_on_private_chat"}
                                        value={values.payment_on_private_chat}
                                        error={!!touched.payment_on_private_chat && !!errors.payment_on_private_chat}
                                        helperText={touched.payment_on_private_chat && errors.payment_on_private_chat}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Kazanılan Coin Miktarı(dk)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"earning_on_private_chat"}
                                        value={values.earning_on_private_chat}
                                        error={!!touched.earning_on_private_chat && !!errors.earning_on_private_chat}
                                        helperText={touched.earning_on_private_chat && errors.earning_on_private_chat}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <Typography variant='h6' sx={{ gridColumn: "span 4", marginTop: "10px" }}>Para Çekimleri</Typography>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Coin Başına Ödenecek Miktar(₺)"
                                        inputProps={{ step: "0.01" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"one_coin_tl_price_double"}
                                        value={values.one_coin_tl_price_double}
                                        error={!!touched.one_coin_tl_price_double && !!errors.one_coin_tl_price_double}
                                        helperText={touched.one_coin_tl_price_double && errors.one_coin_tl_price_double}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Minimum Çekim Miktarı(coin)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"minimum_withdrawable_coin"}
                                        value={values.minimum_withdrawable_coin}
                                        error={!!touched.minimum_withdrawable_coin && !!errors.minimum_withdrawable_coin}
                                        helperText={touched.minimum_withdrawable_coin && errors.minimum_withdrawable_coin}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Gönder</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default SettingsDialog
import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function PostDialog({ targetPost, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        text: targetPost?.text ? targetPost?.text : "",
        price: targetPost?.price ? targetPost?.price : 0,
        content_link: targetPost?.content_link ? targetPost?.content_link : ""
    };

    var validationObject = {
        text: yup.string().required("Zorunlu"),
        price: yup.number().min(0, "0'dan düşük olamaz").required("Zorunlu"),
        content: yup.mixed().nullable().when('content_link', {
            is: (content_link) => !content_link || content_link === "",
            then: yup.string().nonNullable("").required('Zorunlu')
        }),
        content_link: yup.string()
    }

    const postSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    Gönderiyi Düzenle
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={postSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">Gönderi İçeriği</Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], marginTop: "10px", minHeight: "250px", minWidth: "250px" }}>
                                        {
                                            (
                                                (
                                                    values.content &&
                                                    (
                                                        values.content.name.split(".").pop() === "jpg" ||
                                                        values.content.name.split(".").pop() === "png" ||
                                                        values.content.name.split(".").pop() === "jpeg" ||
                                                        values.content.name.split(".").pop() === "webp"
                                                    )
                                                )
                                                ||
                                                (
                                                    values.content_link &&
                                                    values.content_link !== "" &&
                                                    (
                                                        values.content_link.split(".").pop() === "jpg" ||
                                                        values.content_link.split(".").pop() === "png" ||
                                                        values.content_link.split(".").pop() === "jpeg" ||
                                                        values.content_link.split(".").pop() === "webp"
                                                    )
                                                )
                                            )
                                            && <img
                                                alt='post content'
                                                width="100px"
                                                src={values.content ? URL.createObjectURL(values.content) : values.content_link}
                                                style={{ cursor: "pointer", borderRadius: "5%", maxHeight: "250px", width: "auto" }} />
                                        }{
                                            (
                                                (
                                                    values.content &&
                                                    (
                                                        values.content.name.split(".").pop() === "mp4" ||
                                                        values.content.name.split(".").pop() === "flv" ||
                                                        values.content.name.split(".").pop() === "mov"
                                                    )
                                                )
                                                ||
                                                (
                                                    values.content_link &&
                                                    values.content_link !== "" &&
                                                    (
                                                        values.content_link.split(".").pop() === "mp4" ||
                                                        values.content_link.split(".").pop() === "flv" ||
                                                        values.content_link.split(".").pop() === "mov"
                                                    )
                                                )
                                            )
                                            && <video
                                                alt='post content'
                                                src={values.content ? URL.createObjectURL(values.content) : values.content_link}
                                                controls={true}
                                                style={{ cursor: "pointer", borderRadius: "5%", height: "150px" }} />
                                        }
                                        <input id="content" name="content" type="file" accept=".png, .jpeg, .jpg .webp .mp4 .flv .mov" onChange={(event) => { setFieldValue("content", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(8, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <Box sx={{ gridColumn: "span 1", marginTop: "20px" }}></Box>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Yazı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"text"}
                                        value={values.text}
                                        error={!!touched.text && !!errors.text}
                                        helperText={touched.text && errors.text}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Fiyat"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"price"}
                                        value={values.price}
                                        error={!!touched.price && !!errors.price}
                                        helperText={touched.price && errors.price}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />

                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default PostDialog